<template>
  <div>
    <input type="text" v-model="input" @keyup.enter="add">
    <ul>
      <li v-for="todo in todos" v-bind:key="todo.txt">
        {{ todo.txt }}
        <span class="actions">
          <button @click="edit">edit</button>&nbsp;
          <button @click="trash">trash</button>&nbsp;
          <button @click="done">done</button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import 'firebase/firestore'
import firebase from 'firebase/app'



const config = {
  apiKey: "AIzaSyD7YIyb53PR_hhqc7PZA_BT5tZzfQBhq2E",
  authDomain: "cloudflare-serverless.firebaseapp.com",
  databaseURL: "https://cloudflare-serverless-default-rtdb.firebaseio.com",
  projectId: "cloudflare-serverless",
  storageBucket: "cloudflare-serverless.appspot.com",
  messagingSenderId: "335165195074",
  appId: "1:335165195074:web:14e5ee3421185cd858bedb"
};

firebase.initializeApp(config)
const db = firebase.firestore()
window.db = db

export default ({
  data: () => {
    return {
      input: "",
      todos: [],
    }
  },

  firestore: {
    todos: db.collection('todos'),
  },

  methods: {
    add() {
      db.collection('todos').add({txt: this.input})
      this.input = ""
    },
    edit(newtxt) {
      this.input =
      console.log(newtxt)
    },
    trash() {
      console.log('trash')
    },
    done() {
      console.log('done')
    }
  },
})
</script>

<style scoped>

  .actions {
    visibility: hidden;
    float:  right;
  }

  li {
    width:  200px;
  }

  li:hover .actions {
    visibility:  visible;
  }
</style>
